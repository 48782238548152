import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconArticleComponent } from './icon-article.component';

@NgModule({
  declarations: [IconArticleComponent],
  imports: [CommonModule],
  exports: [IconArticleComponent]
})
export class IconArticleModule {}

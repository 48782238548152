import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsTableComponent } from './payments-table.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [PaymentsTableComponent],
  imports: [CommonModule, PipesModule],
  exports: [PaymentsTableComponent]
})
export class PaymentsTableModule {}

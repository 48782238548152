import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardDetailComponent } from './card-detail.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [CardDetailComponent],
  imports: [CommonModule, PipesModule],
  exports: [CardDetailComponent]
})
export class CardDetailModule {}

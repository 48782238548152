import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyLiteralPipe, CurrencySplitPipe } from './currency.pipe';
import { DatePipe } from './date.pipe';
import { PhonePipe } from './phone.pipe';

@NgModule({
  declarations: [CurrencySplitPipe, CurrencyLiteralPipe, DatePipe, PhonePipe],
  exports: [CurrencySplitPipe, CurrencyLiteralPipe, DatePipe, PhonePipe],
  imports: [CommonModule]
})
export class PipesModule {}

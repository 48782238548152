import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalRequisitesComponent } from './modal-requisites.component';
import { PaymentsTableModule } from '../../../payments-table/payments-table.module';
import { CreditTableModule } from '../../../credit-table/credit-table.module';
import { CreditHolidayModule } from '../../../credit-holiday/credit-holiday.module';
import { TransferTableModule } from '../../../transfer-table/transfer-table.module';

@NgModule({
  declarations: [ModalRequisitesComponent],
  imports: [CommonModule, PaymentsTableModule, CreditTableModule, CreditHolidayModule, TransferTableModule],
  exports: [ModalRequisitesComponent]
})
export class ModalRequisitesModule {}

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export enum Modals {
  stats = 'statsModal',
  requisites = 'requisitesModal',
  operations = 'operationsModal',
  imperiaRewards = 'imperiaRewardsModal',
  pdfError = 'pdfErrorModal'
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private activeModal = new ReplaySubject<Modals>(1);
  public activeModal$ = this.activeModal.asObservable();

  open(modalName: Modals) {
    this.activeModal.next(modalName);
  }

  close() {
    this.activeModal.next(null);
  }
}

import { Injectable } from '@angular/core';
import { Modals, ModalService } from './modal.service';
import { StoreService } from '../../core/services/store.service';
import { HttpService } from '../../core/services/http.service';
import { FileSaver } from './file-saver';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor(private store: StoreService, private modalService: ModalService, private http: HttpService) {}

  public downloadPdf() {
    this.http.getPdfFile(this.store.hash, this.store.cardType).subscribe(this.next, this.error);
  }

  private next = value => {
    FileSaver.saveFile(value);
  };

  private error = () => {
    this.modalService.open(Modals.pdfError);
  };
}

import { Component, OnInit } from '@angular/core';
import { combineLatest, timer } from 'rxjs';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'state-sms-authorization',
  templateUrl: './sms-authorization.component.html',
  styleUrls: ['./sms-authorization.component.less']
})
export class SmsAuthorizationComponent implements OnInit {
  timer$;
  value = new FormControl('');
  inputCorrect = 'correct';
  constructor(
    private data: DataService,
    private router: Router,
    private http: HttpService,
    private store: StoreService
  ) {}

  ngOnInit() {
    this.startTimer();
    this.value.valueChanges.subscribe(value => {
      this.value.patchValue(value.replace(/\D/gm, ''), { emitEvent: false });
    });
  }

  startTimer() {
    if (this.timer$ && this.timer$.unsubscribe) this.timer$.unsubscribe();
    this.timer$ = timer(1000, 1000).pipe(take(61));
  }

  sendCode(code: string) {
    this.http.checkSMS(this.store.hash, code).subscribe(
      () => {
        this.inputCorrect = 'correct';
        this.router.navigateByUrl(this.store.cardType);
      },
      () => {
        this.inputCorrect = 'incorrect';
        this.startTimer();
      }
    );
  }

  getCode() {
    this.startTimer();
  }
}

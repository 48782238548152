import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfoModule } from './components/user-info/user-info.module';
import { PipesModule } from './pipes/pipes.module';
import { PenaltyModule } from './components/penalty/penalty.module';
import { RequisitesModule } from './components/requisites/requisites.module';
import { PageLoaderModule } from './components/page-loader/page-loader.module';
import { ModalModule } from './components/modal/modal.module';
import { IconsBlockModule } from './components/icons-block/icons-block.module';
import { IconArticleModule } from './components/icon-article/icon-article.module';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';
import { CreditHolidayModule } from './components/credit-holiday/credit-holiday.module';
import { CreditTableModule } from './components/credit-table/credit-table.module';
import { CardDetailModule } from './components/card-detail/card-detail.module';
import { BarcodeModule } from './components/barcode/barcode.module';
import { DirectivesModule } from './directives/directives.module';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    UserInfoModule,
    PipesModule,
    PenaltyModule,
    PageLoaderModule,
    RequisitesModule,
    ModalModule,
    IconsBlockModule,
    IconArticleModule,
    HeaderModule,
    FooterModule,
    CreditHolidayModule,
    CreditTableModule,
    CardDetailModule,
    BarcodeModule,
    DirectivesModule
  ]
})
export class SharedModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages.component';
import { RouterModule } from '@angular/router';
import { PagesRoutingModule } from './pages-routing.module';
import { PageLoaderModule } from '../../shared/components/page-loader/page-loader.module';
import { PdfErrorModalModule } from '../../shared/components/modal/modals/modal-pdf-error/pdf-error-modal.module';
import { SmsAuthorizationModule } from './sms-authorization/sms-authorization.module';
import { ErrorMessageModule } from './error-message/error-message.module';
import { ModalModule } from '../../shared/components/modal/modal.module';
import { FooterModule } from '../../shared/components/footer/footer.module';

@NgModule({
  declarations: [PagesComponent],
  exports: [PagesComponent],
  imports: [
    CommonModule,
    RouterModule,
    PagesRoutingModule,
    PageLoaderModule,
    PdfErrorModalModule,
    SmsAuthorizationModule,
    ErrorMessageModule,
    ModalModule,
    FooterModule
  ]
})
export class PagesModule {}

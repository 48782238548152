import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { OperationByCard, CardArrayKeys, CardAlias } from '../../../core/models/models';
import { DataService } from '../../../core/services/data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-operations-table',
  templateUrl: './operations-table.component.html',
  styleUrls: ['./operations-table.component.less']
})
export class OperationsTableComponent implements OnInit {
  @Input() public title = '';
  @Input() public currency2 = 'р';

  public operationAliases: CardAlias[];

  public currency: Observable<string>;

  public mainCardsArray: Observable<OperationByCard>;

  public additionalCardsArray: Observable<OperationByCard>;

  constructor(public modalService: ModalService, public data: DataService) {}

  ngOnInit(): void {
    this.currency = this.data.currency$;
    this.operationAliases = this.data.paymentsListAliases;
    this.mainCardsArray = this.data.operationList$.pipe(map(data => data[CardArrayKeys.main]));
    this.additionalCardsArray = this.data.operationList$.pipe(
      map(data => (data[CardArrayKeys.additional] ? data[CardArrayKeys.additional] : {}))
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PenaltyComponent } from './penalty.component';

@NgModule({
  declarations: [PenaltyComponent],
  exports: [PenaltyComponent],
  imports: [CommonModule]
})
export class PenaltyModule {}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'state-modal-statistics',
  templateUrl: './modal-statistics.component.html',
  styleUrls: ['./modal-statistics.component.css']
})
export class ModalStatisticsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

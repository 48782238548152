import { IndebtednessInfo } from '../../../core/models/models';
import { ModalService } from '../../services/modal.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'app-payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.less']
})
export class PaymentsTableComponent implements OnInit {
  indebtedness: Observable<IndebtednessInfo[]>;
  currency: Observable<string>;
  constructor(public modalService: ModalService, public data: DataService) {}

  ngOnInit() {
    this.indebtedness = this.data.indebtednessList$;
    this.currency = this.data.currency$;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationsTableComponent } from './operations-table.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [OperationsTableComponent],
  imports: [CommonModule, PipesModule],
  exports: [OperationsTableComponent]
})
export class OperationsTableModule {}

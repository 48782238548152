import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CardTypes, CheckHash, Statement } from '../models/models';

@Injectable()
export class HttpService {
  constructor(public http: HttpClient) {}
  getStatement(hash: string, fingerPrint: string, cardType: CardTypes): Observable<Statement> {
    const url = `api/statement/get-statement`;

    return this.http.post(url, { hash, fingerPrint, cardType }) as Observable<Statement>;
  }

  checkClientHash(hash: string): Observable<CheckHash> {
    // Проверка необходимости ввода СМС
    const url = `api/statement/init?hash=${hash}`;

    return this.http.get(url) as Observable<CheckHash>;
  }

  checkSMS(hash: string, code: string) {
    const query = code ? `&smsCode=${code}` : ``;
    const url = `api/statement/check-sms?hash=${hash}${query}`;

    return this.http.get(url);
  }

  getPdfFile(hash: string, cardType: string) {
    const url = `api/statement/print?hash=${hash}&template=${cardType}`;

    return this.http.get(url, {
      responseType: 'blob'
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from '../../../core/models/models';
import { DataService } from '../../../core/services/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: ' state-requisites',
  templateUrl: './requisites.component.html',
  styleUrls: ['./requisites.component.less']
})
export class RequisitesComponent implements OnInit {
  @Input() public showBarcode;
  public currency: Observable<string>;
  public userInfo: Observable<UserInfo>;
  constructor(public data: DataService) {}

  ngOnInit() {
    this.userInfo = this.data.userInfo$;
    this.currency = this.data.currency$;
  }
}

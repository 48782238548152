import { Component, OnInit } from '@angular/core';
import { CardAlias, InstallmentInfo } from '../../../core/models/models';
import { DataService } from '../../../core/services/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-credit-table',
  templateUrl: './credit-table.component.html',
  styleUrls: ['./credit-table.component.less']
})
export class CreditTableComponent implements OnInit {
  aliases: CardAlias[];
  aliasesMobile: CardAlias[];
  installmentList: InstallmentInfo[];
  total: InstallmentInfo;

  public currency: Observable<string>;

  constructor(public data: DataService) {}

  ngOnInit() {
    this.aliases = this.data.instalmentListAliases;
    this.aliasesMobile = this.getMobileAliases(this.aliases);
    this.currency = this.data.currency$;
    this.data.installmentInfo$.subscribe(list => {
      this.installmentList = Object.assign([], list);
      this.total = this.installmentList.pop();
    });
  }

  private getMobileAliases(aliases: CardAlias[]) {
    const mobileAliases = Object.assign([], aliases);

    mobileAliases.splice(4, 0, mobileAliases[0]);
    mobileAliases.splice(8, 0, mobileAliases[0]);

    return mobileAliases;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon } from '../../../core/models/models';

@Component({
  selector: 'app-icon-article',
  templateUrl: './icon-article.component.html',
  styleUrls: ['./icon-article.component.less']
})
export class IconArticleComponent {
  @Input() icon: Icon;
  @Input() cardType = '';
  @Output() btnClick = new EventEmitter<string>();

  cardClick() {
    this.btnClick.emit(this.icon.name);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CardTypes, Statement } from '../models/models';
import { filter, map, shareReplay } from 'rxjs/operators';

interface State {
  statement: Statement;
  hash: string;
  loader: boolean;
  cardType: CardTypes;
}

const initialState: State = {
  statement: null,
  hash: null,
  loader: false,
  cardType: null
};

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private readonly state = new BehaviorSubject<State>(initialState);

  public statement$: Observable<Statement> = this.state.asObservable().pipe(
    filter((item: any) => item.statement !== null),
    map((item: Statement) => item.statement),
    shareReplay()
  );

  public loader$: Observable<boolean> = this.state.asObservable().pipe(map((item: State) => item.loader));
  public get hash(): string {
    return this.state.getValue().hash;
  }

  public cardType$: Observable<CardTypes> = this.state.asObservable().pipe(
    filter((item: State) => item.cardType !== null),
    map((item: State) => item.cardType),
    shareReplay()
  );

  public get cardType(): CardTypes {
    return this.state.getValue().cardType;
  }

  public errorMessage: string;

  public setLoading(loader: boolean): void {
    this.state.next({
      ...this.state.value,
      loader
    });
  }

  public setHash(hash: string): void {
    this.state.next({
      ...this.state.value,
      hash
    });
  }

  public setCardType(cardType: CardTypes): void {
    this.state.next({
      ...this.state.value,
      cardType
    });
  }

  public setStatement(statement: Statement): void {
    this.state.next({
      ...this.state.value,
      statement
    });
  }
}

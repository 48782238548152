import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../../core/services/data.service';

@Component({
  selector: 'state-modal-requisites',
  templateUrl: './modal-requisites.component.html',
  styleUrls: ['./modal-requisites.component.less']
})
export class ModalRequisitesComponent implements OnInit {
  constructor(public data: DataService) {}

  ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';
import { HolidayTypes, UserInfo } from '../../../core/models/models';
import { DataService } from '../../../core/services/data.service';
import { Observable } from 'rxjs';
import * as moment from "moment";

@Component({
  selector: 'state-credit-holiday',
  templateUrl: './credit-holiday.component.html',
  styleUrls: ['./credit-holiday.component.less']
})
export class CreditHolidayComponent implements OnInit {
  currency: Observable<string>;

  alias = {
    [HolidayTypes.gracePeriod]: {
      firstBlock: 'ПО\u00A0ДОГОВОРУ БЫЛ ПРЕДОСТАВЛЕН ЛЬГОТНЫЙ ПЕРИОД ПО\u00A0ЗАКОНУ №\u00A0106-ФЗ',
      firstBlockLongPeriod: 'ПРЕДОСТАВЛЕН ЛЬГОТНЫЙ ПЕРИОД ПО\u00A0ЗАКОНУ №\u00A0106-ФЗ',
      secondBlock: {
        title: 'График платежей по\u00A0зафиксированной сумме',
        totalPaymentsAmount: 'Зафиксированная сумма',
        lastPaymentDate: 'Дата оплаты последнего платежа по\u00A0зафиксированной сумме',
        currentPaymentAmount: 'Сумма очередного платежа по\u00A0зафиксированной сумме',
        currentPaymentDate: 'Дата оплаты очередного платежа по\u00A0зафиксированной сумме',
        remainingPayments: 'Количество оставшихся платежей'
      }
    },
    [HolidayTypes.defermentOfPayment]: {
      firstBlock: 'Участник акции \u00ABОтсрочка платежа\u00BB',
      secondBlock: {
        title: 'Платежи по\u00A0начисленным процентам',
        totalPaymentsAmount: 'Начисленные проценты',
        lastPaymentDate: 'Дата оплаты последнего платежа по\u00A0начисленным процентам',
        currentPaymentAmount: 'Сумма очередного платежа по\u00A0начисленным процентам',
        currentPaymentDate: 'Дата оплаты очередного платежа по\u00A0начисленным процентам',
        remainingPayments: 'Количество оставшихся платежей по\u00A0начисленным процентам'
      }
    },
    [HolidayTypes.creditHolidays]: {
      firstBlock: 'Предоставлены кредитные каникулы',
      secondBlock: {
        title: 'График платежей по\u00A0зафиксированной сумме',
        totalPaymentsAmount: 'Зафиксированная сумма',
        lastPaymentDate: 'Дата оплаты последнего платежа по\u00A0зафиксированной сумме',
        currentPaymentAmount: 'Сумма очередного платежа по\u00A0зафиксированной сумме',
        currentPaymentDate: 'Дата оплаты очередного платежа по\u00A0зафиксированной сумме',
        remainingPayments: 'Количество оставшихся платежей'
      }
    },
    [HolidayTypes.mobilizationParticipants]: {
      firstBlock: 'ПО\u00A0ДОГОВОРУ БЫЛ ПРЕДОСТАВЛЕН ЛЬГОТНЫЙ ПЕРИОД ПО\u00A0ЗАКОНУ №\u00A0377-ФЗ',
      firstBlockLongPeriod: 'ПРЕДОСТАВЛЕН ЛЬГОТНЫЙ ПЕРИОД ПО\u00A0ЗАКОНУ №\u00A0377-ФЗ',
      secondBlock: {
        title: 'График платежей по\u00A0зафиксированной сумме',
        totalPaymentsAmount: 'Зафиксированная сумма',
        lastPaymentDate: 'Дата оплаты последнего платежа по\u00A0зафиксированной сумме',
        currentPaymentAmount: 'Сумма очередного платежа по\u00A0зафиксированной сумме',
        currentPaymentDate: 'Дата оплаты очередного платежа по\u00A0зафиксированной сумме',
        remainingPayments: 'Количество оставшихся платежей'
      }
    },
    [HolidayTypes.difficultFinancialSituation]: {
      firstBlock: 'ПО\u00A0ДОГОВОРУ БЫЛ ПРЕДОСТАВЛЕН ЛЬГОТНЫЙ ПЕРИОД ПО\u00A0ЗАКОНУ №\u00A0353-ФЗ',
      firstBlockLongPeriod: 'ПРЕДОСТАВЛЕН ЛЬГОТНЫЙ ПЕРИОД ПО\u00A0ЗАКОНУ №\u00A0353-ФЗ',
      secondBlock: {
        title: 'График платежей по\u00A0зафиксированной сумме',
        totalPaymentsAmount: 'Зафиксированная сумма',
        lastPaymentDate: 'Дата оплаты последнего платежа по\u00A0зафиксированной сумме',
        currentPaymentAmount: 'Сумма очередного платежа по\u00A0зафиксированной сумме',
        currentPaymentDate: 'Дата оплаты очередного платежа по\u00A0зафиксированной сумме',
        remainingPayments: 'Количество оставшихся платежей'
      }
    },
    [HolidayTypes.livingInDangerousArea]: {
      firstBlock: 'ПО\u00A0ДОГОВОРУ БЫЛ ПРЕДОСТАВЛЕН ЛЬГОТНЫЙ ПЕРИОД ПО\u00A0ЗАКОНУ №\u00A0353-ФЗ',
      firstBlockLongPeriod: 'ПРЕДОСТАВЛЕН ЛЬГОТНЫЙ ПЕРИОД ПО\u00A0ЗАКОНУ №\u00A0353-ФЗ',
      secondBlock: {
        title: 'График платежей по\u00A0зафиксированной сумме',
        totalPaymentsAmount: 'Зафиксированная сумма',
        lastPaymentDate: 'Дата оплаты последнего платежа по\u00A0зафиксированной сумме',
        currentPaymentAmount: 'Сумма очередного платежа по\u00A0зафиксированной сумме',
        currentPaymentDate: 'Дата оплаты очередного платежа по\u00A0зафиксированной сумме',
        remainingPayments: 'Количество оставшихся платежей'
      }
    },
  };

  constructor(public data: DataService) {}

  ngOnInit() {
    this.currency = this.data.currency$;
  }

  findDifferenceInDates(date1: string, date2: string) {
    if (!date1 || !date2) {
      return -1;
    }
    const dateStart = moment(date1, 'DD.MM.YYYY');
    const dateEnd = moment(date2, 'DD.MM.YYYY');
    return dateEnd.diff(dateStart, 'days');
  }

  hideCreditHolidayPlate(typeOfHoliday: string, userInfo: UserInfo, lastPaymentDate: string): boolean {
    return (typeOfHoliday === "1" || typeOfHoliday === "4" || typeOfHoliday === "5" || typeOfHoliday === "6") &&
      (this.findDifferenceInDates(userInfo.billingBeginDate, userInfo.billingEndDate) < 31) &&
      (this.findDifferenceInDates(userInfo.payUpToDate, lastPaymentDate) < 0);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditTableComponent } from './credit-table.component';
import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  declarations: [CreditTableComponent],
  imports: [CommonModule, PipesModule, DirectivesModule],
  exports: [CreditTableComponent]
})
export class CreditTableModule {}

export enum CurrencyLiterals {
  USD = '$',
  RUR = 'р',
  EUR = '€',
  RUB = 'р',
  GBP = '£'
}

export class Formatter {
  public static formatMoneyToStr(number: string | number) {
    if (number === undefined) return;

    if (typeof number === 'string') {
      number = number.replace(/[^0-9.]/g, '');
    }
    const sign = number < 0 ? '-' : '';
    number = Math.abs(Number(number) || 0);

    return sign + Formatter.toDecimalPlaces(number);
  }

  public static toDecimalPlaces(number: number): string {
    return number.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1\u00A0');
  }

  public static formatMoneyToNumber(number: string | number): number {
    if (number === undefined) return;

    if (typeof number === 'string') {
      number = number.replace(/\s/g, '');
    }

    return Number(number) || 0;
  }

  public static formatPhone(phoneStr: string) {
    if (phoneStr === undefined) return;

    return phoneStr.replace(/[а-я ()-]/gi, '');
  }

  public static dataConvert(str: string): string {
    // Костыль для проверки валидности даты
    function isValidDate(d) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return d instanceof Date && !isNaN(d);
    }
    if (!str) return '';

    const initDate = new Date(str);
    const replaceDate = new Date(str.replace('.', '/'));

    if (isValidDate(initDate)) {
      return initDate.toLocaleDateString('ru');
    }

    if (isValidDate(replaceDate)) {
      return replaceDate.toLocaleDateString('ru');
    }

    return str;
  }
}

import { Component, OnInit } from '@angular/core';
import { BaseModal } from '../../../../../core/models/models';
import { Modals, ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'state-pdf-error-modal',
  templateUrl: './pdf-error-modal.component.html',
  styleUrls: ['./pdf-error-modal.component.less']
})
export class PdfErrorModalComponent {
  constructor(public modalService: ModalService) {}
}

import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CardAlias, Details, UserInfo } from '../../../core/models/models';
import { Calculator } from '../../services/calculator';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.less']
})
export class CardDetailComponent implements OnInit {
  @Input() cardDetail: Details;
  userInfo$: Observable<UserInfo>;
  needPayment$: Observable<string>;
  currency$: Observable<string>;

  constructor(public modalService: ModalService, public data: DataService) {}

  cardClick() {
    if (this.cardDetail.modal) {
      this.modalService.open(this.cardDetail.modal);
    }
    if (this.cardDetail.href) {
      window.open(this.cardDetail.href, '_blank');
    }
  }

  showItem(objList: object[], alias: CardAlias): boolean {
    const targetObj = objList.filter((item) => Object.prototype.hasOwnProperty.call(item, alias.name))[0];

    return alias.show(targetObj[alias.name]);
  }

  ngOnInit(): void {
    this.needPayment$ = this.data.userInfo$.pipe(map((userInfo: UserInfo) => Calculator.getPayment(userInfo)));
  }
}

import { ModalImperiaRewardsComponent } from './modals/modal-imperia-rewards/modal-imperia-rewards.component';
import { Modals, ModalService } from '../../services/modal.service';
import { Subscription } from 'rxjs';
import { PdfErrorModalComponent } from './modals/modal-pdf-error/pdf-error-modal.component';
import { ModalRequisitesComponent } from './modals/modal-requisites/modal-requisites.component';
import { ModalStatisticsComponent } from './modals/modal-statistics/modal-statistics.component';
import { ModalOperationsComponent } from './modals/modal-operations/modal-operations.component';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { filter } from 'rxjs/operators';
import set = Reflect.set;

@Component({
  selector: 'state-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @ViewChild('output', { read: ViewContainerRef, static: false }) output;
  private readonly element: HTMLElement;
  private modalComponents = {
    [Modals.stats]: ModalStatisticsComponent,
    [Modals.requisites]: ModalRequisitesComponent,
    [Modals.operations]: ModalOperationsComponent,
    [Modals.imperiaRewards]: ModalImperiaRewardsComponent,
    [Modals.pdfError]: PdfErrorModalComponent
  };

  private subs: Subscription = new Subscription();

  constructor(
    public modalService: ModalService,
    private el: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const openModal$ = this.modalService.activeModal$.pipe(filter((item: Modals) => !!this.modalComponents[item]));
    const closeModal$ = this.modalService.activeModal$.pipe(filter(item => item === null));

    // setTimeout потому что ng-container находится в блоке *ngIf и не зарендерен на странице
    this.subs.add(openModal$.subscribe(item => setTimeout(() => this.renderComponent(this.modalComponents[item]))));
    this.subs.add(closeModal$.subscribe(() => this.removeComponent()));
  }

  private renderComponent(component): void {
    const modalComponent = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = this.output.createComponent(modalComponent);
    //TODO: костыль, пока не найдено лучшего решения
    document.querySelector('body').style.overflow = 'hidden';
  }

  private removeComponent(): void {
    this.output.clear();
    document.querySelector('body').style.overflow = 'auto';
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

import { Component, Injectable, OnInit } from '@angular/core';

@Component({
  selector: 'state-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.less']
})
@Injectable({
  providedIn: 'root'
})
export class PageLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

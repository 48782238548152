import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StoreService } from './store.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private store: StoreService, private router: Router, private zone: NgZone) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handle = next.handle(req);

    return handle.pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('error', error);
        if (!req.url.includes('check-sms') && !req.url.includes('print')) {
          this.store.errorMessage = error.error.message;
          this.zone.run(() => {
            this.router.navigateByUrl('/error').then(() => {
              this.store.setLoading(false);
            });
          });
        }

        return throwError(error);
      })
    );
  }
}

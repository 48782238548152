import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalOperationsComponent } from './modal-operations.component';
import { OperationsTableModule } from '../../../operations-table/operations-table.module';

@NgModule({
  declarations: [ModalOperationsComponent],
  imports: [CommonModule, OperationsTableModule],
  exports: [ModalOperationsComponent]
})
export class ModalOperationsModule {}

import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DataService } from '../../../core/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'state-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit, AfterViewInit {
  @Input() public hideBarcode!: boolean;
  @ViewChild('footerBlock', { static: true }) footerBlock: ElementRef;

  constructor(public data: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private r2: Renderer2) {}

  ngOnInit(): void {

  }

  initCookie() {

    const block = this.r2.createElement('div');
    this.r2.setAttribute(block,'id','cookie-block');
    this.r2.setAttribute(block,'data-style', "font-size:12px;font-family:'LatoMedium',sans-serif;color:#333;");
    this.r2.appendChild(this.footerBlock.nativeElement, block);

    const script = this.r2.createElement('script');
    this.r2.setProperty(script,'src', 'https://rsb.ru/upload/external/js/cookie-info.js');
    this.r2.setAttribute(script,'defer', '');
    this.r2.appendChild(document.body, script);
  }

  ngAfterViewInit(): void {
    this.initCookie();
  }
}

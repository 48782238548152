import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SmsAuthorizationComponent } from './sms-authorization/sms-authorization.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { PagesGuard } from './pages.guard';
import { CardTypes } from '../models/models';
import { PagesComponent } from './pages.component';
import { BarcodeGuard } from '../../shared/barcode.guard';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: CardTypes.bvk,
        pathMatch: 'full',
        loadChildren: () => import('./rsb/rsb.module').then((m) => m.RsbModule),
        canActivate: [PagesGuard],
        data: {
          isHideBarcode: true
        }
      },
      {
        path: CardTypes.new,
        pathMatch: 'full',
        loadChildren: () => import('./new/new.module').then((m) => m.NewModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.dci,
        pathMatch: 'full',
        loadChildren: () => import('./dinersclub/dinersclub.module').then((m) => m.DinersclubModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.dciPrem,
        pathMatch: 'full',
        loadChildren: () => import('./diners-premium/diners-premium.module').then((m) => m.DinersPremiumModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.aep,
        pathMatch: 'full',
        loadChildren: () => import('./amex/amex.module').then((m) => m.AmexModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.amg,
        pathMatch: 'full',
        loadChildren: () => import('./amex-gold/amex-gold.module').then((m) => m.AmexGoldModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.bap,
        pathMatch: 'full',
        loadChildren: () => import('./british-airways/british-airways.module').then((m) => m.BritishAirwaysModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.cnt,
        pathMatch: 'full',
        loadChildren: () => import('./centurion/centurion.module').then((m) => m.CenturionModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.visaInf,
        pathMatch: 'full',
        loadChildren: () => import('./visa-infinite/visa-infinite.module').then((m) => m.VisaInfiniteModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.travelCredit,
        pathMatch: 'full',
        loadChildren: () => import('./travel-credit/travel-credit.module').then((m) => m.TravelCreditModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.travelDebit,
        pathMatch: 'full',
        loadChildren: () => import('./travel-debit/travel-debit.module').then((m) => m.TravelDebitModule),
        canActivate: [PagesGuard],
        data: {
          isHideBarcode: true
        }
      },
      {
        path: CardTypes.mmCredit,
        pathMatch: 'full',
        loadChildren: () => import('./mm-credit/mm-credit.module').then((m) => m.MmCreditModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.mmDebit,
        pathMatch: 'full',
        loadChildren: () => import('./mm-debit/mm-debit.module').then((m) => m.MmDebitModule),
        canActivate: [PagesGuard],
        data: {
          isHideBarcode: true
        }
      },
      {
        path: CardTypes.imperia,
        pathMatch: 'full',
        loadChildren: () => import('./imperia/imperia.module').then((m) => m.ImperiaModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.viMult,
        pathMatch: 'full',
        loadChildren: () => import('./vi-mult/vi-mult.module').then((m) => m.ViMultModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.debit,
        pathMatch: 'full',
        loadChildren: () => import('./debit/debit.module').then((m) => m.DebitModule),
        canActivate: [PagesGuard],
        data: {
          isHideBarcode: true
        }
      },
      {
        path: CardTypes.prem,
        pathMatch: 'full',
        loadChildren: () => import('./prem/prem.module').then((m) => m.PremModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.priv,
        pathMatch: 'full',
        loadChildren: () => import('./priv/priv.module').then((m) => m.PrivModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.hunDays,
        pathMatch: 'full',
        loadChildren: () => import('./hun-days/hun-days.module').then((m) => m.HunDaysModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.cashBack,
        pathMatch: 'full',
        loadChildren: () => import('./cash-back/cash-back.module').then((m) => m.CashBackModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.amexM,
        pathMatch: 'full',
        loadChildren: () => import('./amex-m/amex-m.module').then((m) => m.AmexMModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.dciM,
        pathMatch: 'full',
        loadChildren: () => import('./dci-m/dci-m.module').then((m) => m.DciMModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.privPlus,
        pathMatch: 'full',
        loadChildren: () => import('./priv-plus/priv-plus.module').then((m) => m.PrivPlusModule),
        canActivate: [PagesGuard]
      },
      {
        path: CardTypes.supreme,
        pathMatch: 'full',
        loadChildren: () => import('./supreme/supreme.module').then((m) => m.SupremeModule),
        canActivate: [PagesGuard]
      },
      {
        path: 'authorization',
        pathMatch: 'full',
        component: SmsAuthorizationComponent,
        canActivate: [BarcodeGuard],
        data: {
          isHideBarcode: true
        }
      },
      {
        path: 'error',
        pathMatch: 'full',
        component: ErrorMessageComponent,
        canActivate: [BarcodeGuard],
        data: {
          isHideBarcode: true
        }
      },
      {
        path: '**',
        redirectTo: 'error'
      }
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}

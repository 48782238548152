import { Component, Input } from '@angular/core';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'state-penalty',
  templateUrl: './penalty.component.html',
  styleUrls: ['./penalty.component.less']
})
export class PenaltyComponent {
  @Input() public isCreditCard = true;
  @Input() link = 'https://www.rsb.ru/platezhi-perevody/s-karty-na-kartu/';
  constructor(public data: DataService) {}
}

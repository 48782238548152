import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequisitesComponent } from './requisites.component';
import { PipesModule } from '../../pipes/pipes.module';
import { BarcodeModule } from '../barcode/barcode.module';

@NgModule({
  declarations: [RequisitesComponent],
  imports: [CommonModule, PipesModule, BarcodeModule],
  exports: [RequisitesComponent]
})
export class RequisitesModule {}

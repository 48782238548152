import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserInfo } from '../../../core/models/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoComponent {
  @Input() userInfo$: Observable<UserInfo>;
  @Input() cardImage: Observable<string>;
  @Input() cardType$: Observable<string>;
  @Input() tariffHref$: Observable<string>;
}

import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StoreService } from './store.service';
import { HttpService } from './http.service';
import { CardTypes, CheckHash, Statement } from '../models/models';
import { FingerprintService } from '../../shared/services/fingerprint.service';
import { Observable, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  private $fingerprint = FingerprintService.getFingerprint();
  public hideBarcode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: StoreService,
    private httpService: HttpService,
    private zone: NgZone
  ) {}

  private setError = (err) => {
    this.zone.run(() => {
      if (err.error && err.error.message) {
        this.store.errorMessage = err.error.message;
      }
      this.router.navigateByUrl('/error').then(() => {
        this.store.setLoading(false);
      });
    });

    return of(false);
  };

  private setSVData = (item: Statement): boolean => {
    this.store.setStatement(item);
    this.store.setLoading(false);

    return true;
  };

  public canLoad(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store.setLoading(true);

    return this.store.hash ? this.getSVData(this.store.hash) : this.initApp(route);
  }

  private initApp(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store.setCardType(route.routeConfig.path as CardTypes);
    const hash = route.queryParams.hash;
    this.store.setHash(hash);

    if (hash) {
      return this.httpService.checkClientHash(hash).pipe(
        switchMap((val: CheckHash) => (val.smsRequired ? this.goToSMSAuth() : this.getSVData(hash))),
        catchError(this.setError)
      );
    } else {
      this.router.navigateByUrl('/error').then(() => {
        this.store.setLoading(false);
      });

      return of(false);
    }
  }

  private goToSMSAuth(): Observable<boolean> {
    this.router.navigateByUrl('/authorization').then(() => {
      this.store.setLoading(false);
    });

    return of(true);
  }

  private getSVData(hash: string): Observable<boolean> {
    return this.$fingerprint.pipe(
      switchMap((fp: string) => this.httpService.getStatement(hash, fp, this.store.cardType).pipe(map(this.setSVData)))
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import * as JsBarcode from 'jsbarcode';

@Component({
  selector: 'barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.less']
})
export class BarcodeComponent implements OnInit {
  @Input() barCode;
  constructor() {}

  ngOnInit() {
    if (this.barCode !== undefined) {
      JsBarcode('#barcode', this.barCode, {
        width: 6,
        height: 100,
        displayValue: false,
        background: 'transparent'
      });
    }
  }
}

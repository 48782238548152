import * as Fingerprint2 from 'fingerprintjs2';
import { bindCallback, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export class FingerprintService {
  public static getFingerprint(): Observable<string> {
    const fn = bindCallback(Fingerprint2.getV18)();

    return fn.pipe(
      map(([result, components]) => JSON.stringify(components)),
      shareReplay()
    );
  }
}

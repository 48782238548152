import { Pipe, PipeTransform } from '@angular/core';
import { Formatter } from '../services/Formatter';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return;

    return Formatter.formatPhone(value);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalImperiaRewardsComponent } from './modal-imperia-rewards.component';

@NgModule({
  declarations: [ModalImperiaRewardsComponent],
  imports: [CommonModule],
  exports: [ModalImperiaRewardsComponent]
})
export class ModalImperiaRewardsModule {}

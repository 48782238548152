import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'state-modal-operations',
  templateUrl: './modal-operations.component.html',
  styleUrls: ['./modal-operations.component.less']
})
export class ModalOperationsComponent implements OnInit {
  @Input() public currency: string;
  @Input() public schet: string;

  constructor() {}

  ngOnInit() {}
}

import { CardAlias } from '../../core/models/models';

export const operationsList: CardAlias[] = [
  {
    alias: 'Дата операции',
    name: 'operationDate',
    type: 'date'
  },
  {
    alias: 'Описание операции',
    name: 'transactionStatus'
  },
  {
    alias: 'Детали операции',
    name: 'transactionDesc'
  },
  {
    alias: 'Сумма операции',
    name: 'amount',
    type: 'amount'
  }
];

export const instalmentList: CardAlias[] = [
  {
    alias: 'Дата операции',
    name: 'transactionDate',
    type: 'date'
  },
  {
    alias: 'Сумма операции',
    name: 'transactionAmount',
    type: 'amount'
  },
  {
    alias: 'Описание операции',
    name: 'description1'
  },
  {
    alias: 'Сумма операции, переведенной в Кредит с рассрочкой',
    name: 'creditTransactionAmount',
    type: 'amount'
  },
  {
    alias: 'Дата перевода операции в Кредит с рассрочкой',
    name: 'instalmentsDate',
    type: 'date'
  },
  {
    alias: 'Дата возврата Кредита с рассрочкой',
    name: 'instalmentsExpDate',
    type: 'date'
  },
  {
    alias: 'Сумма очередного платежа по Кредиту с рассрочкой',
    name: 'paymentAmount',
    type: 'amount'
  },
  {
    alias: 'Количество оставшихся платежей по Кредиту с рассрочкой',
    name: 'paymentsRemain'
  },
  {
    alias: 'Невозвращенная сумма Кредита с рассрочкой',
    name: 'debtAmount',
    type: 'amount'
  }
];
export const paymentCardAliases: { scores: CardAlias[]; bonuses: CardAlias[] } = {
  scores: [
    {
      alias: 'Зачислено баллов: ',
      name: 'rewardAmount',
      grey: true
    },
    {
      alias: 'Остаток на бонусном счете: ',
      name: 'finalBalance',
      grey: true
    }
  ],
  bonuses: [
    {
      alias: 'Зачислено бонусов: ',
      name: 'rewardAmount',
      grey: true
    },
    {
      alias: 'Остаток на бонусном счете: ',
      name: 'finalBalance',
      grey: true
    }
  ]
};
export const transferBalanceList: CardAlias[] = [
  {
    alias: 'Дата совершения Расходной операции',
    name: 'transactionDate',
    type: 'date'
  },
  {
    alias: 'Сумма Расходной операции',
    name: 'transactionAmount',
    type: 'amount'
  },
  {
    alias: 'Описание Расходной операции',
    name: 'description1'
  },
  {
    alias: 'Дата, которой определен срок полного возврата Перевода баланса (Рефинансирование)',
    name: 'instalmentsExpDate',
    type: 'date'
  },
  {
    alias: 'Сумма очередного платежа',
    name: 'paymentAmount',
    type: 'amount'
  },
  {
    alias: 'Дата оплаты очередного платежа',
    name: 'payUpToDate',
    type: 'date'
  },
  {
    alias: 'Количество оставшихся очередных платежей',
    name: 'paymentsRemain'
  },
  {
    alias: 'Невозвращенная (непогашенная Клиентом) сумма перевода баланса (Рефинансирования)',
    name: 'debtAmount',
    type: 'amount'
  }
];

export enum HowToReplanishLinks {
  rsb = 'https://www.rsb.ru/cards/sposoby-popolnenia/'
}

export enum HowToSpendLinks {
  dci = 'https://www.dinersclubcard.ru/privilege/cashback/rewards/',
  rsb = 'https://www.rsb.ru/cards/discounts/moscow/',
  aep = 'https://www.americanexpress.ru/personal/privilege/membership-rewards/catalog/',
  imperia = 'https://imperia.rsb.ru/privilegii/#visualScroll',
  mm = 'https://www.miles-and-more.com/row/ru.html',
  travel = 'https://www.rsb.ru/upload/doc/offer-bonus-travel.pdf',
  bap = 'https://www.britishairways.com/ru-ru/executive-club/about-the-club?source=MNVEXC1about_the_executive_club'
}

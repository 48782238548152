import { Component, HostListener, OnInit } from '@angular/core';
import { StoreService } from './core/services/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'dci';

  constructor(public store: StoreService) {}

  @HostListener('window:beforeunload', ['$event'])
  handleUnload(e) {
    e.preventDefault();
    e.returnValue = true;

    return true;
  }

  ngOnInit(): void {}
}

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { ModalService } from '../../services/modal.service';
import { MccInfo } from '../../../core/models/models';
import { DataService } from '../../../core/services/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.less']
})
export class StatisticsComponent implements OnInit, AfterViewInit {
  public topMcc: Array<MccInfo>;
  public topMccYear: Array<MccInfo>;

  public currLiteral: Observable<string>;

  colors = ['#1b7c8b', '#c9433d', '#e2a14b', '#297840', '#781968', '#dce0e6'];
  colorsClasses = ['circle_blue', 'circle_red', 'circle_orange', 'circle_green', 'circle_purple', 'circle_white'];

  @ViewChild('chartPeriod') chartPeriod: ElementRef;
  @ViewChild('chartYear') chartYear: ElementRef;

  constructor(public modalService: ModalService, public data: DataService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.currLiteral = this.data.currency$;
  }

  ngAfterViewInit() {
    this.data.topMcc$.subscribe(topMcc => {
      this.topMcc = topMcc;
      this.createChart(this.topMcc, this.chartPeriod);
    });
    this.data.topMccYear$.subscribe(topMccYear => {
      this.topMccYear = topMccYear;
      this.createChart(this.topMccYear, this.chartYear);
    });
    this.cd.detectChanges();
  }

  private createChart(mccs: MccInfo[], element: ElementRef) {
    const topArray: Array<number> = mccs.map(item => +item.percentTop5);
    const topArrayNames: Array<string> = mccs.map(item => item.MCC);
    // const ctx = document.getElementById(elementId);
    this.getChart(element, topArrayNames, topArray);
  }

  private getChart(ctx1: ElementRef, arrayNames: Array<string>, arrayValues: Array<number>) {
    return new Chart(ctx1.nativeElement, {
      type: 'doughnut',
      data: {
        labels: arrayNames,
        datasets: [
          {
            label: '# of Votes',
            data: arrayValues,
            backgroundColor: this.colors,
            borderWidth: 0
          }
        ]
      },
      options: {
        scales: {
          yAxis: {
            beginAtZero: true,
            display: false
          }
        },
        plugins: {
          legend: {
            display: false
          },
        },
        cutout: '65%'
      }
    });
  }
}

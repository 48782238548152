import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditHolidayComponent } from './credit-holiday.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [CreditHolidayComponent],
  imports: [CommonModule, PipesModule],
  exports: [CreditHolidayComponent]
})
export class CreditHolidayModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { Formatter } from '../services/Formatter';

@Pipe({
  name: 'datePipe'
})
export class DatePipe implements PipeTransform {
  transform(value: string): string {
    return Formatter.dataConvert(value);
  }
}

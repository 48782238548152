import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsBlockComponent } from './icons-block.component';
import { IconArticleModule } from '../icon-article/icon-article.module';

@NgModule({
  declarations: [IconsBlockComponent],
  exports: [IconsBlockComponent],
  imports: [CommonModule, IconArticleModule]
})
export class IconsBlockModule {}

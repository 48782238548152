import { Component, Input } from '@angular/core';
import { Icon } from '../../../core/models/models';
import { ModalService } from '../../services/modal.service';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'state-icons-block',
  templateUrl: './icons-block.component.html',
  styleUrls: ['./icons-block.component.less']
})
export class IconsBlockComponent {
  @Input() icons: Icon[];
  @Input() isShadow = false;

  clickHandler(icnName): void {
    const icon: Icon = this.icons.find(itm => itm.name === icnName);
    if (!icon) {
      return;
    }

    if (icon.href) {
      window.open(icon.href, '_blank');
    }
    if (icon.modal) {
      this.modalService.open(icon.modal);
    }
  }

  constructor(public modalService: ModalService, public data: DataService) {}
}

import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'state-modal-rewards',
  templateUrl: './modal-rewards.component.html',
  styleUrls: ['./modal-rewards.component.less']
})
export class ModalImperiaRewardsComponent implements OnInit {
  constructor(public modalService: ModalService) {}

  ngOnInit() {}
}

import { Component } from '@angular/core';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'state-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.less']
})
export class ErrorMessageComponent {
  public errorInfo: string;

  constructor(private store: StoreService) {
    this.errorInfo = this.store.errorMessage
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { PdfErrorModalModule } from './modals/modal-pdf-error/pdf-error-modal.module';
import { PdfErrorModalComponent } from './modals/modal-pdf-error/pdf-error-modal.component';
import { ModalRequisitesModule } from './modals/modal-requisites/modal-requisites.module';
import { ModalRequisitesComponent } from './modals/modal-requisites/modal-requisites.component';
import { ModalStatisticsModule } from './modals/modal-statistics/modal-statistics.module';
import { ModalStatisticsComponent } from './modals/modal-statistics/modal-statistics.component';
import { ModalImperiaRewardsComponent } from './modals/modal-imperia-rewards/modal-imperia-rewards.component';
import { ModalImperiaRewardsModule } from './modals/modal-imperia-rewards/modal-imperia-rewards.module';
import { ModalOperationsComponent } from './modals/modal-operations/modal-operations.component';
import { ModalOperationsModule } from './modals/modal-operations/modal-operations.module';

@NgModule({
  declarations: [ModalComponent],
  imports: [
    CommonModule,
    ModalStatisticsModule,
    ModalRequisitesModule,
    PdfErrorModalModule,
    ModalImperiaRewardsModule,
    ModalOperationsModule
  ],
  exports: [ModalComponent],
  entryComponents: [
    ModalOperationsComponent,
    ModalRequisitesComponent,
    PdfErrorModalComponent,
    ModalStatisticsComponent,
    ModalImperiaRewardsComponent
  ]
})
export class ModalModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransferTableComponent } from './transfer-table.component';
import { PipesModule } from '../../pipes/pipes.module';



@NgModule({
  declarations: [TransferTableComponent],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [TransferTableComponent]
})
export class TransferTableModule { }

import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { InitService } from '../core/services/init.service';


@Injectable({
  providedIn: 'root'
})
export class BarcodeGuard implements CanActivate {
  constructor(private route: ActivatedRoute, private initService: InitService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.initService.hideBarcode$.next(route.data['isHideBarcode']);
    return of(true)
  }
}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../core/services/data.service';
import { CardAlias, InstallmentInfo } from '../../../core/models/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'state-transfer-table',
  templateUrl: './transfer-table.component.html',
  styleUrls: ['./transfer-table.component.less']
})
export class TransferTableComponent implements OnInit {
  aliases: CardAlias[];
  aliasesMobile: CardAlias[];
  installmentList: InstallmentInfo[];
  total: InstallmentInfo;

  public currency: Observable<string>;

  private getMobileAliases(aliases: CardAlias[]): CardAlias[] {
    const mobileAliases = Object.assign([], aliases);

    mobileAliases.splice(4, 0, mobileAliases[0]);
    mobileAliases.splice(8, 0, mobileAliases[0]);

    return mobileAliases;
  }

  constructor(public data: DataService) { }

  ngOnInit(): void {
    this.aliases = this.data.transferBalanceListAliases;
    this.aliasesMobile = this.getMobileAliases(this.aliases);
    this.currency = this.data.currency$;
    this.data.transferBalanceInfo$.subscribe(list => {
      this.installmentList = Object.assign([], list);
      this.total = this.installmentList.pop();
    });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfErrorModalComponent } from './pdf-error-modal.component';

@NgModule({
  declarations: [PdfErrorModalComponent],
  exports: [PdfErrorModalComponent],
  imports: [CommonModule]
})
export class PdfErrorModalModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalStatisticsComponent } from './modal-statistics.component';
import { StatisticsModule } from '../../../statistics/statistics.module';

@NgModule({
  declarations: [ModalStatisticsComponent],
  imports: [CommonModule, StatisticsModule],
  exports: [ModalStatisticsComponent]
})
export class ModalStatisticsModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyLiterals, Formatter } from '../services/Formatter';

@Pipe({
  name: 'currencySplit'
})
export class CurrencySplitPipe implements PipeTransform {
  transform(value: string | number): string {
    return Formatter.formatMoneyToStr(value);
  }
}


@Pipe({
  name: 'currencyLiteral'
})
export class CurrencyLiteralPipe implements PipeTransform {
  transform(value: string | number): string {
    return CurrencyLiterals[value];
  }
}

import { UserInfo } from '../../core/models/models';
import { Formatter } from './Formatter';

export class Calculator {
  public static getPayment(data: UserInfo): string {
    if (+data.endPeriodSum < 0) {
      if ([1, 2].includes(+data.minimalCashPercentageType)) {
        return Formatter.formatMoneyToStr(
          Formatter.formatMoneyToNumber(data.minPaymentSum) - Formatter.formatMoneyToNumber(data.minimalPercentageDebt)
        );
      }
      if (data.minimalCashPercentageType === 3) {
        return Formatter.formatMoneyToStr(
          Formatter.formatMoneyToNumber(data.minPaymentSum) - Formatter.formatMoneyToNumber(data.endPeriodSum)
        );
      }
    } else {
      return Formatter.formatMoneyToStr(data.minPaymentSum);
    }
  }
}

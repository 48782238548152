/* eslint-disable sonarjs/no-duplicate-string */
import { Card, CardDetailsConfig, CardTypes, Details, IconConfig } from '../models/models';
import { Modals } from '../../shared/services/modal.service';
import { HowToReplanishLinks, HowToSpendLinks, paymentCardAliases } from '../../shared/aliases/aliases';
import { Formatter } from '../../shared/services/Formatter';

class Payments implements Details {
  id = Card.payments;
  show = false;
  title = 'БОНУСЫ';
  aliases = paymentCardAliases.bonuses;
  availableDescription = true;
  constructor(model?: Partial<Details>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}
const defaultPayments = new Payments();
const dciPayments = new Payments({
  title: 'Diners Club Rewards',
  aliases: paymentCardAliases.scores
});
const imperiaPayments = new Payments({
  title: 'Imperia Rewards',
  modal: Modals.imperiaRewards,
  aliases: paymentCardAliases.scores
});
const amexPayments = new Payments({
  title: 'MEMBERSHIP REWARDS',
  availableDescription: false
});
const premPayments = new Payments({
  title: 'IMPERIA REWARDS',
  availableDescription: false,
  aliases: paymentCardAliases.scores
});
const supremePayments = new Payments({
  title: 'RS Cashback',
  availableDescription: false,
  aliases: paymentCardAliases.bonuses,
});
const paymentsWithoutBtn = new Payments({
  availableDescription: false
});
const credit: Details = {
  id: Card.credit,
  show: true,
  title: 'ПЛАТЕЖНАЯ ИНФОРМАЦИЯ',
  modal: Modals.requisites,
  aliases: [
    {
      alias: 'Сумма для оплаты',
      name: 'minPaymentSum',
      curr: ' р',
      type: 'amount'
    },
    {
      alias: 'Оплатить до: ',
      name: 'payUpToDate',
      type: 'date'
    },
    {
      alias: 'Сумма для оплаты в льготный период',
      name: 'gracePeriodAmount',
      curr: ' р',
      grey: true,
      type: 'amount'
    },
    {
      alias: 'Дата окончания льготного периода',
      name: 'gracePeriodExpireDate',
      grey: true,
      type: 'date'
    },
    {
      alias: 'Доступный остаток лимита за вычетом обязательных платежей',
      name: 'availableBalanceByLimit',
      curr: ' р',
      grey: true,
      type: 'amount'
    }
  ]
};
const operations: Details = {
  id: Card.operations,
  show: true,
  title: 'ИНФОРМАЦИЯ ПО ДОГОВОРУ',
  modal: Modals.operations,
  aliases: [
    {
      alias: 'Лимит',
      name: 'creditLimit',
      curr: ' р',
      grey: true,
      type: 'amount',
      show: (val) => Formatter.formatMoneyToNumber(val) > 0
    },
    {
      alias: 'Доступно на начало периода',
      name: 'availableAtStartCalc',
      curr: ' р',
      type: 'amount'
    },
    {
      alias: 'Поступления',
      name: 'debit',
      curr: ' р',
      grey: true,
      type: 'amount'
    },
    {
      alias: 'Расходы',
      name: 'creditSumCommission',
      curr: ' р',
      grey: true,
      type: 'amount'
    },
    {
      alias: 'Доступно на конец периода',
      name: 'availableAtStopCalc',
      curr: ' р',
      type: 'amount'
    }
  ]
};
const cashBack: Details = {
  id: Card.cashBack,
  show: true,
  title: 'ВОЗМЕЩЕНИЕ',
  href: 'https://tariffolds.rsb.ru/f/1/documents/document_241_2020-08-14.pdf',
  aliases: [
    {
      alias: 'Зачислено',
      name: 'title'
    },
    {
      alias: 'По ставке 1%',
      name: 'cashBackType2str',
      curr: ' р',
      type: 'amount'
    },
    {
      alias: 'По ставке 3%',
      name: 'cashBackType3str',
      curr: ' р',
      type: 'amount',
      show: (val) => Formatter.formatMoneyToNumber(val) > 0
    },
    {
      alias: 'Расходные операции',
      name: 'title'
    },
    {
      alias: 'Общая сумма с',
      addAlias: 'dogYear',
      name: 'cashBackWhole',
      curr: ' р',
      type: 'amount'
    },
    {
      alias: 'Оставшаяся сумма для возмещения по ставке 3%',
      name: 'sumSpendType3remained',
      curr: ' р',
      type: 'amount',
      show: (val) => Formatter.formatMoneyToNumber(val) > 0
    }
  ],
  availableDescription: true
};
// Карточки на главной странице
export const cardDetails: CardDetailsConfig = {
  [CardTypes.dci]: {
    operations,
    credit,
    payments: dciPayments
  },
  [CardTypes.amg]: {
    operations,
    credit,
    payments: defaultPayments
  },
  [CardTypes.aep]: {
    operations,
    credit,
    payments: amexPayments
  },
  [CardTypes.dciPrem]: {
    operations,
    credit,
    payments: dciPayments
  },
  [CardTypes.debit]: {
    operations,
    payments: defaultPayments
  },
  [CardTypes.imperia]: {
    operations,
    credit,
    payments: imperiaPayments
  },
  [CardTypes.bvk]: {
    operations,
    credit,
    payments: defaultPayments
  },
  [CardTypes.bap]: {
    operations,
    credit,
    payments: defaultPayments
  },
  [CardTypes.travelCredit]: {
    operations,
    credit,
    payments: defaultPayments
  },
  [CardTypes.travelDebit]: {
    operations,
    credit,
    payments: defaultPayments
  },
  [CardTypes.mmCredit]: {
    operations,
    credit,
    payments: defaultPayments
  },
  [CardTypes.mmDebit]: {
    operations
  },
  [CardTypes.new]: {
    operations,
    credit,
    payments: defaultPayments
  },
  [CardTypes.visaInf]: {
    operations,
    credit,
    payments: imperiaPayments
  },
  [CardTypes.cnt]: {
    operations,
    credit,
    payments: amexPayments
  },
  [CardTypes.viMult]: {
    operations,
    credit,
    payments: imperiaPayments
  },
  [CardTypes.redMult]: {
    operations,
    credit,
    payments: imperiaPayments
  },
  [CardTypes.prem]: {
    operations,
    credit,
    payments: premPayments
  },
  [CardTypes.priv]: {
    operations,
    credit,
    payments: amexPayments
  },
  [CardTypes.hunDays]: {
    operations,
    credit,
    payments: defaultPayments
  },
  [CardTypes.cashBack]: {
    operations,
    credit,
    cashBack
  },
  [CardTypes.amexM]: {
    operations,
    credit,
    payments: paymentsWithoutBtn
  },
  [CardTypes.dciM]: {
    operations,
    credit,
    payments: paymentsWithoutBtn
  },
  [CardTypes.privPlus]: {
    operations,
    credit,
    payments: new Payments({
      title: 'IMPERIA REWARDS',
      availableDescription: false,
      aliases: paymentCardAliases.scores
    })
  },
  [CardTypes.supreme]: {
    operations,
    credit,
    payments: supremePayments
  },
};
//Нижние кнопки
export const iconConf: IconConfig = {
  [CardTypes.visaInf]: [
    {
      src: '../../../assets/img/icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/imperia-rewards.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.imperia,
      show: false
    },
    {
      src: '../../../assets/img/icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.aep]: [
    {
      src: '../../../assets/img/icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.prem]: [
    {
      src: '../../../assets/img/icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.amg]: [
    {
      src: '../../../assets/img/amex-icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/amex-icon-02.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.aep,
      show: false
    },
    {
      src: '../../../assets/img/amex-icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.bap]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-Avios.png',
      id: 'howToSpend',
      name: 'ПОДРОБНЕЕ',
      href: HowToSpendLinks.bap,
      show: false
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.cnt]: [
    {
      src: '../../../assets/img/icons/icn-cnt-Sposob.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-cnt-Structure.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.priv]: [
    {
      src: '../../../assets/img/icons/icn-cnt-Sposob.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-cnt-Structure.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.debit]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-cashback.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.rsb,
      show: false
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.dci]: [
    {
      src: '../../../assets/img/icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icon-02.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.dci,
      show: false
    },
    {
      src: '../../../assets/img/icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.dciPrem]: [
    {
      src: '../../../assets/img/icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icon-02.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.dci,
      show: false
    },
    {
      src: '../../../assets/img/icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.imperia]: [
    {
      src: 'assets/img/icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: 'assets/img/imperia-rewards.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.imperia,
      show: false
    },
    {
      src: 'assets/img/icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.mmCredit]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-cashback.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.mm,
      show: false
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.mmDebit]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-cashback.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.mm,
      show: false
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.new]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-cashback.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.rsb,
      show: false
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.hunDays]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-cashback.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.rsb,
      show: false
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.redMult]: [
    {
      src: '../../../assets/img/icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/imperia-rewards.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.imperia,
      show: false
    },
    {
      src: '../../../assets/img/icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.bvk]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-cashback.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.travelCredit]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-Travel.svg',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.travel,
      show: false
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.travelDebit]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-Travel.svg',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      href: HowToSpendLinks.travel,
      show: false
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.viMult]: [
    {
      src: '../../../assets/img/icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/imperia-rewards.png',
      id: 'howToSpend',
      name: 'КАК ПОТРАТИТЬ',
      modal: Modals.imperiaRewards,
      show: false
    },
    {
      src: '../../../assets/img/icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.cashBack]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.amexM]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.dciM]: [
    {
      src: '../../../assets/img/icons/icn-rsb-Sposob.svg',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-rsb-Structure.svg',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.privPlus]: [
    {
      src: '../../../assets/img/icons/icn-cnt-Sposob.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: '../../../assets/img/icons/icn-cnt-Structure.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ],
  [CardTypes.supreme]: [
    {
      src: 'assets/img/icon-01.png',
      id: 'howToReplanish',
      name: 'СПОСОБЫ ПОПОЛНЕНИЯ',
      href: HowToReplanishLinks.rsb,
      show: true
    },
    {
      src: 'assets/img/icon-03.png',
      id: 'stats',
      name: 'СТРУКТУРА РАСХОДОВ',
      modal: Modals.stats,
      show: false
    }
  ]
};

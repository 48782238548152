import { Injectable } from '@angular/core';
import { StoreService } from './store.service';
import { combineLatest, Observable } from 'rxjs';
import {
  BonusProgramInfo,
  CardAlias,
  CardDetails,
  CardInfoList,
  CashBack,
  CreditHolidaysInfo,
  CreditPaymentsInfo,
  Details,
  IndebtednessInfo,
  InstallmentInfo,
  MccInfo,
  OperationList,
  Statement,
  UserInfo
} from '../models/models';
import { map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { CurrencyLiterals, Formatter } from '../../shared/services/Formatter';
import { cardDetails, iconConf } from '../pages/pages-config';
import {
  instalmentList,
  operationsList,
  transferBalanceList
} from '../../shared/aliases/aliases';
import { KeyValue } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private store: StoreService) {}
  public instalmentListAliases: CardAlias[] = instalmentList;
  public transferBalanceListAliases: CardAlias[] = transferBalanceList;
  public paymentsListAliases: CardAlias[] = operationsList;
  public cardType$ = this.store.cardType$;
  public userInfo$: Observable<UserInfo> = this.store.statement$.pipe(
    map((statement: Statement) => statement.statementList.statementInfo && statement.statementList.statementInfo[0]),
    shareReplay()
  );

  public currency$: Observable<string> = this.userInfo$.pipe(
    map((userInfo: UserInfo) => CurrencyLiterals[userInfo.currency])
  );

  public phone$: Observable<string> = this.userInfo$.pipe(
    map((userInfo: UserInfo) => (userInfo.phoneNumberMoscow ? userInfo.phoneNumberMoscow : userInfo.phoneNumberRegions))
  );

  public hasPenalty$: Observable<boolean> = this.userInfo$.pipe(
    map((userInfo: UserInfo) => userInfo.isPreviousPaymentPaid === '0')
  );

  public barCode$: Observable<string> = this.userInfo$.pipe(map((userInfo: UserInfo) => `#${userInfo.clientContractNumber}*`));
  public tariffHref$: Observable<string> = this.store.statement$.pipe(map((statement: Statement) => statement.tariffPlan));
  public cardImage$: Observable<string> = this.store.statement$.pipe(map((statement: Statement) => statement.cardImageUrl));
  public topMcc$: Observable<MccInfo[]> = this.store.statement$.pipe(
    map((statement: Statement) => statement.mccByPeriodList && statement.mccByPeriodList.mccInfo)
  );

  public topMccYear$: Observable<MccInfo[]> = this.store.statement$.pipe(
    map((statement: Statement) => statement.mccByYearList && statement.mccByYearList.mccInfo)
  );

  public bonusProgramInfo$: Observable<BonusProgramInfo> = this.store.statement$.pipe(
    map((statement: Statement) => {
      const data = statement.bonusProgramsInfoList && statement.bonusProgramsInfoList.bonusProgramsInfo[0];
      if (!data) {
        return null;
      }
      data.finalBalance = Formatter.formatMoneyToStr(data.finalBalance);
      data.rewardAmount = Formatter.formatMoneyToStr(data.rewardAmount);

      return data;
    }),
    shareReplay()
  );

  private getInfo = (info: InstallmentInfo[]) => {
    if (!info || !info.length) {
      return;
    }
    info.forEach(item => {
      if(item.description2) {
        item.description1 = item.description1 + '\n' + item.description2;
      }
    });

    return info;
  };

  public installmentInfo$: Observable<InstallmentInfo[]> = this.store.statement$.pipe(
    map((statement: Statement) => {
      const info = statement.instalmentInfoList && statement.instalmentInfoList.instalmentInfo;

      return this.getInfo(info);
    }),
    shareReplay()
  );

  public transferBalanceInfo$: Observable<InstallmentInfo[]> = this.store.statement$.pipe(
    map((statement: Statement) => {
      const info = statement.transferBalanceInfoList;

      return this.getInfo(info);
    }),
    shareReplay()
  );

  public indebtednessList$: Observable<IndebtednessInfo[]> = this.store.statement$.pipe(
    withLatestFrom(this.userInfo$),
    map(([statement, userInfo]) => {
      const list = statement.indebtednessList && statement.indebtednessList.indebtednessInfo;
      if (+userInfo.totalAmount) {
        list.push({
          amount: Formatter.formatMoneyToStr(userInfo.totalAmount),
          transactionStatus: 'Сумма очередного платежа по Кредиту с рассрочкой'
        });
      }
      if (+userInfo.floatTransferBalanceAmount) {
        list.push({
          amount: Formatter.formatMoneyToStr(userInfo.floatTransferBalanceAmount),
          transactionStatus: 'Сумма очередного платежа по Переводу баланса'
        });
      }

      return list;
    }),
    shareReplay()
  );

  public creditPaymentsInfo$: Observable<CreditPaymentsInfo> = this.store.statement$.pipe(
    map((statement: Statement) => statement.creditPaymentsList && statement.creditPaymentsList.creditPaymentsInfo[0]),
    shareReplay()
  );

  public creditHolidaysInfo$: Observable<CreditHolidaysInfo> = this.store.statement$.pipe(
    map((statement: Statement) => statement.creditHolidaysList && statement.creditHolidaysList.creditHolidaysInfo[0]),
    shareReplay()
  );

  public creditVacation$: Observable<boolean> = this.userInfo$.pipe(map((userInfo: UserInfo) => userInfo.creditVacation === 1));
  public paymentsCardsInfo$: Observable<CardInfoList> = this.store.statement$.pipe(
    map((statement: Statement) => statement.cardInfoList.cardInfo),
    shareReplay()
  );

  public icons$ = this.cardType$.pipe(
    switchMap((cardType: string) => {
      const icons = iconConf[cardType];

      return combineLatest([this.bonusProgramInfo$, this.topMcc$, this.topMccYear$, this.userInfo$]).pipe(
        map(([bonusProgram, topMcc, topMccYear, userInfo]) => {
          if (bonusProgram) {
            const bonusIcon = icons.find((icon) => icon.id === 'howToSpend');
            if (bonusIcon) {
              bonusIcon.show = true;
              bonusIcon.href = userInfo.urlPrivilege;
            }
          }
          if (topMcc && topMccYear) {
            const statsIcon = icons.find((icon) => icon.id === 'stats');
            if (statsIcon) {
              statsIcon.show = true;
            }
          }

          return icons;
        })
      );
    }),
    shareReplay()
  );

  public operationList$: Observable<OperationList> = this.store.statement$.pipe(
    map((statement: Statement) => statement.operationList && statement.operationList.operationInfo),
    shareReplay()
  );

  public cashBack$: Observable<CashBack> = this.store.statement$.pipe(
    map((cash: Statement) => cash.cashBack.cashBackElement && cash.cashBack.cashBackElement[0]),
    shareReplay()
  );

  private checkOperations = (details: CardDetails): Observable<CardDetails> =>
    this.operationList$.pipe(
      map((operations) => {
        if (!details) {
          return null;
        }
        if (operations && details.operations) {
          details.operations.availableDescription = true;
        }

        return details;
      })
    );

  private checkIndebtedness = (details: CardDetails): Observable<CardDetails> =>
    this.indebtednessList$.pipe(
      map((indebtedness) => {
        if (!details) {
          return null;
        }
        if (indebtedness && details.credit) {
          details.credit.availableDescription = true;
        }

        return details;
      })
    );

  private checkBonusProgram = (details: CardDetails): Observable<CardDetails> =>
    this.bonusProgramInfo$.pipe(
      map((bonusProgram) => {
        if (!details) {
          return null;
        }
        if (bonusProgram && details.payments) {
          details.payments.show = true;
        }

        return details;
      })
    );

  private checkGracePeriod = (details: CardDetails): Observable<CardDetails> =>
    this.userInfo$.pipe(
      map((userInfo: UserInfo) => {
        if (!details) {
          return null;
        }
        if (userInfo.grace31ForGracePeriodBlock !== 1 && details.credit) {
          details.credit.aliases = details.credit.aliases.filter(
            (item) => item.name !== 'gracePeriodAmount' && item.name !== 'gracePeriodExpireDate'
          );
        }

        return details;
      })
    );

  private setPrivelegeLink = (details: CardDetails): Observable<CardDetails> =>
    this.userInfo$.pipe(
      map((userInfo: UserInfo) => {
        if (!details) {
          return null;
        }
        if (details.payments) {
          details.payments.href = userInfo.urlPrivilege;
        }

        return details;
      })
    );

  public cardDetails$: Observable<CardDetails> = this.cardType$.pipe(
    map((cardType: string) => cardDetails[cardType] || null),
    switchMap(this.checkOperations),
    switchMap(this.checkIndebtedness),
    switchMap(this.checkBonusProgram),
    switchMap(this.checkGracePeriod),
    switchMap(this.setPrivelegeLink),
    shareReplay()
  );

  public originalOrder = (a: KeyValue<number, Details>, b: KeyValue<number, Details>): number => 0;
}

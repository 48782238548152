import { Modals } from '../../shared/services/modal.service';

export interface CheckHash {
  smsRequired: 1 | 0;
  message: string;
  requestId: string;
}

export interface Operation {
  operationDate: string;
  amount: string;
  operationId: string;
  currency: string;
  transactionDate?: string;
  MCC?: string;
  additionalCard: string;
  cardNumber: string;
  transactionDesc?: string;
  transactionStatus?: string;
}

export interface OperationByCard {
  [cardNumber: string]: Array<Operation>;
}

export const enum CardArrayKeys {
  main = '0',
  additional = '1'
}

export interface OperationList {
  [CardArrayKeys.main]: OperationByCard;
  [CardArrayKeys.additional]: OperationByCard;
}

export interface CardInfoList {
  [cardNumber: string]: CardInfo;
}

export interface MccInfo {
  MCC: string;
  costsTop5: string;
  percentTop5: string;
  costsBelowTop5?: string;
  percentBelowTop5?: string;
  totalCost?: string;
}

export interface AccumulatedPointsInfo {
  interestRate: string;
  accumulatedPoints: string;
  category: string;
}

export interface TransactionsInfo {
  interestRate?: string;
  tspName?: string;
  transactionAmount?: string;
  calculatedAmtPerTransaction?: string;
  transactionDate?: string;
  category?: string;
  transactionId: string;
  reflectionTransactionDate?: string;
}

export interface UserInfo {
  minPaymentSum: string;
  loyaltyCode?: string;
  productName: string;
  minimalPercentageDebt: string;
  creditLimit: string;
  phoneNumberRegions: string;
  cardTypeId: string | number;
  creditLimitInDecimal: string;
  credit: string;
  minimalCashPercentageType: string | number;
  clientContractNumber: string;
  isPreviousPaymentPaid: string;
  minCashPercentage: string;
  accruedInterest: string;
  graceScheme: string | number;
  availableAtStart: string;
  totalAmount: string;
  billingEndDate: string;
  cardNumber: string;
  payUpToDate: string;
  gracePeriod: string;
  INN: string;
  gracePeriodAmount: string;
  minPayAmount: string;
  availableAtStartCalc: string | number;
  paymentAmount: string;
  gracePeriodExpireDate?: string;
  endPeriodLimit: string;
  expiryDate: string;
  availableAtStop: string;
  loyaltyPointsAccrued: string;
  currency: string;
  debit: string;
  availableBalanceByLimit: string;
  productType: string;
  expensesAmount: string;
  noNameCard: string | number;
  address: string;
  cardTenAttribute: string | number;
  BIK: string;
  checkingAccount: string;
  fullName: string;
  endPeriodSum: string;
  availableAtStopCalc: string | number;
  billingBeginDate: string;
  correspondentAccount: string;
  creditSumCommission: string;
  creditVacation: string | number;
  billingPeriodStatusInGracePeriod: string | number;
  clientPhoneNumber?: string;
  phoneNumberMoscow?: string;
  grace31ForGracePeriodBlock?: number;
  urlPrivilege?: string;
  loyaltyDate?: string;
  floatTransferBalanceAmount?: string;
}

export interface InstallmentInfo {
  totalDebt: string;
  contractNumber: string;
  instalmentsDate?: string;
  transactionDate?: string;
  description2?: string;
  instalmentsExpDate?: string;
  paymentAmount: string | number;
  installmentType: string | number;
  description1?: string;
  creditTransactionAmount?: string | number;
  paymentsRemain: string | number;
  rate?: string;
  transactionAmount: string | number;
  currency: string;
  debtAmount: string | number;
  payUpToDate?: string;
}

export interface BonusProgramInfo {
  reversalAmount: string;
  reversedRewardAmount: string;
  loyaltyProgramCode: string;
  finalBalance: string;
  calculatedAmount: string;
  disbursementAmount: string;
  rewardAmount: string;
  accountNumber: string;
  startingBalance: string;
}

export interface IndebtednessInfo {
  amount: string;
  operationDate?: string;
  transactionStatus: string;
}

export interface CardInfo {
  totalCosts: string;
  accrual: string;
}

export enum HolidayTypes {
  gracePeriod = '1',
  defermentOfPayment = '2',
  creditHolidays = '3',
  mobilizationParticipants = '4',
  difficultFinancialSituation = '5',
  livingInDangerousArea = '6'
}

export interface CreditHolidaysInfo {
  typeOfHoliday: HolidayTypes;
  dateBegin: string;
  dateEnd: string;
}

export interface CreditPaymentsInfo {
  totalPaymentsAmount: string;
  lastPaymentDate: string;
  currentPaymentAmount: string;
  currentPaymentDate: string;
  remainingPayments: number;
}

export interface Statement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [property: string]: any;
  creditHolidaysList?: {
    creditHolidaysInfo: CreditHolidaysInfo[];
  };
  creditPaymentsList?: {
    creditPaymentsInfo: CreditPaymentsInfo[];
  };
  tariffPlan: string;
  indebtednessList?: {
    indebtednessInfo: IndebtednessInfo[];
  };
  mccByPeriodList: {
    mccInfo: MccInfo[];
  };

  mccByYearList: {
    mccInfo: MccInfo[];
  };
  bonusProgramsInfoList?: {
    bonusProgramsInfo: BonusProgramInfo[];
  };
  accumulatedPointsInfoList: {
    accumulatedPointsInfo: AccumulatedPointsInfo[];
  };
  transactionsInfoList: {
    transactionsInfo: TransactionsInfo[];
  };
  collateralId: string;
  leadKeyId: string;
  cardImageUrl: string;
  statementList: {
    statementInfo: UserInfo[];
  };
  operationList: {
    operationInfo: OperationList;
  };
  cardInfoList: {
    cardInfo: CardInfoList;
  };
  instalmentInfoList: {
    instalmentInfo: InstallmentInfo[];
  };
  transferBalanceInfoList: InstallmentInfo[];
}
export interface Icon {
  id: 'stats' | 'howToReplanish' | 'howToSpend';
  src: string;
  name: string;
  href?: string;
  modal?: Modals;
  show: boolean;
}

export enum Card {
  credit = 'credit',
  payments = 'payments',
  operations = 'operations',
  cashBack = 'cashBack'
}

export interface Alias {
  alias: string;
  name: string;
  addAlias?: string;
}

export interface CardAlias extends Alias {
  curr?: string;
  grey?: boolean;
  type?: 'amount' | 'date';
  show?: (val: any) => boolean;
}

export interface Details {
  id: Card;
  title: string;
  aliases: CardAlias[];
  href?: string;
  modal?: Modals;
  show: boolean;
  availableDescription?: boolean;
}

export type CardDetails = {
  [key in Card]?: Details;
};

export enum CardTypes {
  dci = 'dci',
  bvk = 'bvk',
  travelCredit = 'travel-credit',
  travelDebit = 'travel-debit',
  mmCredit = 'mm-credit',
  mmDebit = 'mm-debit',
  bap = 'bap',
  amg = 'amg',
  aep = 'aep',
  dciPrem = 'dci-prem',
  cnt = 'cnt',
  visaInf = 'visa-inf',
  imperia = 'imperia',
  viMult = 'vi-mult',
  redMult = 'red-mult',
  new = 'new',
  debit = 'debit',
  prem = 'prem',
  priv = 'priv',
  hunDays = 'hun-days',
  cashBack = 'cash-back',
  amexM = 'amex-m',
  dciM = 'dci-m',
  privPlus = 'priv_plus',
  supreme = 'supreme'
}

export type CardDetailsConfig = Record<CardTypes, CardDetails>;
export type IconConfig = Record<CardTypes, Icon[]>;

export interface BaseModal {
  id: string;
  open: () => void;
  close: () => void;
}

export interface HeaderLogo {
  phoneLogoHref?: string;
  mailLogoHref?: string;
  saveLogoHref?: string;
}

export interface CashBack {
  cashBackActive: number;
  cashBackAllStr: string;
  cashBackExists: number;
  cashBackHelloStr: string;
  cashBackTotal: string;
  cashBackType2str: string;
  cashBackType3str: string;
  cashBackType9str: string;
  cashBackWhole: string;
  categore: string;
  rateCash: number;
  sumSpendType2: string;
  sumSpendType3: string;
  sumSpendType3remained: string;
  sumSpendType9: string;
  sumSumSpendStr: string;
  dogYear: string;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsAuthorizationComponent } from './sms-authorization.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [SmsAuthorizationComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule]
})
export class SmsAuthorizationModule {}
